import { getSubcategoryPath } from './getSubcategoryPath'

export const getCelebritieLinkParams = (name, id, serializedCategories, categories) => {
  const currentCategories = categories.map((category) =>
    serializedCategories.find(({ id }) => id === category),
  )
  return {
    pathname: `${getSubcategoryPath(currentCategories[0].name, currentCategories[1].name)}/${name
      .toLowerCase()
      .split(' ')
      .join('-')}-net-worth-`,
    query: { i: id },
  }
}

const toValidSubcategory = (sub) => {
  if (
    sub === 'Other Sports' ||
    sub === 'Wall Street' ||
    sub === 'Rock Stars' ||
    sub === 'Celebrity Chefs'
  )
    return sub.split(' ').join('-').toLowerCase()

  return sub.split(' ')[0].toLowerCase()
}

export const getSubcategoryPath = (category, sub) => {
  return `/richest-${category ? category.split(' ')[0].toLowerCase() : ''}/richest-${
    sub ? toValidSubcategory(sub) : ''
  }`
}

export const getGeneralCategoryPath = (category) =>
  `/richest-${category ? category.split(' ')[0].toLowerCase() : ''}`

import { createSlice } from '@reduxjs/toolkit'
import { getConfigs } from './configs.actions'

const initialState = {
  categories: [],
  serializedCategories: [],
  settings: {},
  newestCelebrities: [],
  isLoading: true,
  blogId:
    typeof window !== 'undefined'
      ? localStorage.getItem('lastBlogId')
        ? localStorage.getItem('lastBlogId')
        : null
      : null,
}

export const configsSlice = createSlice({
  name: 'configs',
  initialState,
  reducers: {
    setBlogId(state, { payload }) {
      state.blogId = payload
      localStorage.setItem('lastBlogId', payload)
    },
  },
  extraReducers: {
    [getConfigs.fulfilled]: (state, { payload }) => {
      state.categories = payload.categories.map((category) => ({
        ...category,
        subcategories: category.subcategories.filter(({ name }) => name !== 'undefined'),
      }))

      const serializedCategories = payload.categories.map((category) => ({
        id: category.id,
        name: category.name,
      }))

      payload.categories.forEach(({ subcategories }) =>
        subcategories.forEach((category) => serializedCategories.push(category)),
      )
      state.serializedCategories = serializedCategories

      state.settings = payload.settings.reduce((acc, curr) => {
        Object.assign(acc, curr)
        return acc
      }, {})

      state.newestCelebrities = payload.newestCelebrities
      state.newestCelebrities.pop()

      state.isLoading = false
    },
    [getConfigs.pending]: (state) => {
      state.isLoading = true
    },
    [getConfigs.rejected]: (state) => {
      state.isLoading = false
    },
  },
})

export const { setCelebrityId, setBlogId } = configsSlice.actions

export default configsSlice.reducer
